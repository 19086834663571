import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const MortgageSolutions = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="MortgageSolutions"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Our Mortgage solutions drive Business Process Excellence in mortgage origination and servicing process transformation by combining technology platforms, deep domain experience led by experienced consultative practitioners.</p>
                        <p className="mt-3">Our digital first approach is powered by automated platforms for Intelligent Document Processing​, automated quality control, risk management and regulatory compliance and processing support solution to deliver enhanced multi channel customer experience and operational excellence.</p>
                        <p className="mt-3">Powered by 20 years of industry experience, our Mortgage Services are trusted by several lenders including 4 of the Top 25 U.S Banks.</p>
                        <h6>Some typical examples of what we help our customers achieve:</h6>
                        <h6 className="mt-3">Enhanced customer experience</h6>
                        <ul className="circle-list">
                            <li className="mt-2">4.5/5 CSAT score for a Top 20 U.S Bank</li>
                        </ul>

                        <h6>Operational transformation & efficiency</h6>
                        <ul className="circle-list">
                            <li className="mt-2">30% improved efficiency, 45% lower cost</li>
                        </ul>

                        <h6>Improved quality and governance</h6>
                        <ul className="circle-list">
                            <li className="mt-2">99% improved compliance accuracy</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query MortgageSolutionsPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

MortgageSolutions.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default MortgageSolutions;
